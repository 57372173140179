#lax {
    .lookup-top-location-popover {
        .lx-input-search {
            margin-bottom: 12px;
        }
        .lx-tree {
            .lx-tree-list-scrollbar.lx-tree-list-scrollbar-vertical { 
                background: white; 
            }
            .lx-tree-list-holder {
                > div { 
                    overflow-y: auto !important; 
                    > .lx-tree-list-holder-inner {
                        padding-bottom: 20px;
                    }
                }
            }
            .filter-node .lx-tree-title {
                color: var(--colorPrimary);
                font-weight: bold;
            }

            .lx-tree-node-content-wrapper {
                padding: 0 8px;
            }

            .lx-tree-treenode-selected .lx-tree-node-content-wrapper {
                background-color: var(--colorFocus);
            }
        }
        .lx-skeleton {
            padding: 30px;
        }
        .content {
            height: 550px;
            width: 500px;
            overflow-y: auto;
        }
        .footer {
            padding: 10px 0 0 0;
            border-top: 1px var(--backgroundDisabled) solid;
            display: grid;
            grid-template-columns: auto 150px 150px;
            column-gap: 10px;
        }
    }
}