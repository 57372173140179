#lax {
    --colorIncident: #BBBEC8;

    --colorIncidentWC: #FF9039;
    --colorIncidentAU: #8285ED;
    --colorIncidentLB: #a765e7;
    --colorIncidentWV: #AF675D;
    --colorIncidentHPL: #975790;
    --colorIncidentAA: #CFDB59;
    --colorIncidentPR: #D8CA65;
    --colorIncidentMC: #A2C58B;
}
