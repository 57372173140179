.data-grid {
    display: grid;
    grid-template-columns: 120px 1fr;
    column-gap: 5px;
    row-gap: 8px;
    > span {
        font-weight: bold;
        word-break: break-word;
        > div {
            font-weight: normal;
        }
    }
    .subtitle {
        overflow: hidden;
        font-weight: bold;
        padding-top: 14px;
        &::after {
            content: "";
            display: inline-block;
            height: 1px;
            background-color: var(--colorBorder);
            position: relative;
            vertical-align: middle;
            width: 100%;
            margin-right: -100%;
            left: 15px;
        }
    }
}