#lax {
    .lx-form-item-control-input {
        min-height: unset;
    }
    input {
        font-weight: bold;
    }
    .lx-input-suffix {
        > .anticon {
            color: var(--colorInputIcon);
        }
    }
    .lx-input-affix-wrapper:not(.lx-input-affix-wrapper-disabled) {
        &:has(input:read-only) {
            background-color: var(--backgroundReadonly);
        }
    }
}