@import './incident-types.scss';
@import './floating-panel.scss';
@import './modal-choose-to-continue.scss';
@import './data-grid.scss';
@import './colored.scss';
@import './margin.scss';

.muted {
    opacity: .25;
}
.cursorPointer, .cursorPointer *{
    cursor: pointer;
}
.cursor-pointer {
    cursor: pointer;
}
.with-icon {
    display: flex;
    align-items: center;
    > .anticon {
        margin-right: 7px;
    }
}
.anticon-star {
    color: var(--colorWarning);
}
.double {
    grid-column-start: 1;
    grid-column-end: 3;
}  
