:root {
    --headerHeight: 55px;
    --footerHeight: 55px;
    --subHeaderHeight: 55px;
    --contentPadding: 27px;
    --borderRadius: 6px;
    --inputBorderRadius: 5px;

    --fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; //"Century Gothic", AppleGothic, sans-serif;
    --fontSizeXXS: 10px;
    --fontSizeXS: 11px;
    --fontSizeS: 12px;
    --fontSize: 13px;
    --fontSizeL: 14px;
    --fontSizeXL: 15px;
    --fontSizeXXL: 16px;

    --colorLine: #e1e1e1;
    --colorText: #172B4D; // #2e3a59; //#050505;//
    --colorTextLight: #b9bec9;
    --colorGray: #CCCED5; //#b9bec9;

    --colorDirectiveOrange: #FFAF6F;
    --colorDirectiveInfo: #89A8E2;
    --colorDirectiveInfoBorder: #D2D9F0;
        

    --disabledBg: #EBEBED; // #EBECEE;
    --colorBorder: #E7E7E7;

    --backgroundDisabled: #F5F5F5; // #F0F0F1;// #EFF0F1;
    --backgroundReadonly: #F9F9F9;
    --colorDisabled: rgba(46, 58, 89, .4);
    --colorInputIcon: rgba(46, 58, 89, 0.25);

    --appBackground: #f5f5f5; //#f5f7f9;// 

    --colorBgBase: #ffffff;
    --colorError: #D91413;
    --colorErrorOpacity: #d91313d9;
    --colorErrorMedium: #ffccc7 ;//#FCC7C7; // #F58989;
    --colorErrorLight: #fff2f0;//#FEEFEE; //#FEEFEE #FFCEC6
    --colorErrorDark: #C71212;
    --colorInfo: #2D5AAC;
    --colorInfoMedium: #89A8E2;
    --colorInfoLight: #EEF2FB;
    --colorPrimary: #367CF8;
    --colorSuccess: #5ACA74;
    --colorSuccessLight: #ABD69E;
    --colorSuccessDark: #287C3C;
    --colorWarning: #FF9039;
    --colorWarningLight: #FFB577;
    --colorWarningDark: #B95000;
    --colorFocus: #EBF4FE;
    --colorMuted: #cccccc;
    --controlHeight: 32px;
    --lineType: 'solid';
    --lineWidth: 1px;
    --opacityImage: 1px;
    --sizePopupArrow: 16px;
    --sizeStep: 4px;
    --sizeUnit: 4px;

    --scrollbarThumb: var(--colorGray);
    --fieldsetBackground: white; // #fdfdfd;//#f9f9f9;
    --fieldsetBoxShadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    --innerFormWidth: 500px;
}

:export {
    borderRadius: 5;
    colorBgBase: #ffffff;
    colorError: #D91413;
    colorInfo: #2D5AAC;
    colorPrimary: #367CF8;
    colorSuccess: #5ACA74;
    // #2e3a59;// #050505;
    colorTextBase: #172B4D; 
    colorWarning: #FF9039;
    controlHeight: 32;
    //"Century Gothic", AppleGothic, sans-serif;
    fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; 
    fontSize: 13;
    sizePopupArrow: 16;
    sizeStep: 4;
    sizeUnit: 4;
    colorBorder: #E7E7E7;
}