#lax {
    .header {
        height: var(--headerHeight);
        background-color: white;
        border-bottom: 1px solid var(--colorLine);
        padding: 0 var(--contentPadding);
        width: 100%;
        display: grid;
        grid-template-columns: 150px 1fr;
        align-items: center;
        z-index: 1;
        > div, > a {
            img {
                height: 40px;
            }
            span {
                position: absolute;
                bottom: 0px;
                right: 18px;
                font-size: var(--fontSizeXXS);
                color: var(--colorTextLight);
            }
        }
        > h5 {
            font-size: var(--fontSizeXL);
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 8px;
            justify-self: end;
            > div {
                line-height: 16px;
                font-size: var(--fontSizeL);
            }
        }
    }
    &:has(.header-out-of-view) {
        .panel {
            height: calc(100vh - var(--headerHeight));
            .panel-body {
                height: calc(100vh - var(--headerHeight) - var(--subHeaderHeight));
            }
        }
    }
}