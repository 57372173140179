#lax {
    .modal-choose-to-continue {
        h2 {
            font-size: var(--fontSizeXL);
            margin-top: 5px;
            margin-bottom: 0px;
            > span {
                font-weight: normal;
                color: var(--colorTextLight);
                font-size: var(--fontSize);
            }
        }
        .content {
            min-height: 190px;
        }
        .lx-radio-group {
            margin-top: 10px;
            width: 100%;
            .lx-radio-wrapper {
                width: 100%;
                display: grid;
                grid-template-columns: auto 1fr;
                border-radius: 5px;
                padding: 8px 0px 8px 8px;
                min-height: 46px;
                align-items: center;
                padding-left: 20px;
                &:after {
                    display: none;
                }
                &.lx-radio-wrapper-checked {
                    background: var(--disabledBg);
                }
                > span:not(.lx-radio) {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    > div:nth-of-type(1) {
                        > span {
                            display: block;
                            font-weight: bold;
                        }
                        > div {
                            font-size: var(--fontSizeL);
                        }
                    }
                    > div:nth-of-type(2) {
                        text-align: right;
                        > div {
                            text-align: right;
                            font-weight: bold;
                            color: var(--colorTextLight);
                            font-size: var(--fontSizeL);
                        }
                    }
                }
            }
        }
    }
}