#lax {
    .route {
        article {
            padding-bottom: 40px;
            height: 100%;
            min-height: 100%;
            &:has(form) {
                > form {
                    padding: 20px;
                    width: 100%;
                    justify-self: center;
                    &:not(.full-width) {
                        max-width: var(--innerFormWidth);
                    }
                }
            }
        }
        .outlet {
            --backgroundSize: 350px;
            &::before {
                content: "";
                position: fixed;
                top: 150px; 
                left: 10px;
                width: var(--backgroundSize); 
                height: var(--backgroundSize);
                background-size: var(--backgroundSize);
                background-repeat: no-repeat;
                opacity: .05;
            }
        } 
    }
}