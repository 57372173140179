#lax {
    header {
        position: sticky;
        display: flex;
        align-items: center;
        top: 0;
        padding: 0 var(--contentPadding);
        color: var(--colorTextLight);
        border-bottom: 1px solid var(--colorBorder);
        background-color: var(--appBackground);
        height: var(--subHeaderHeight);
        z-index: 9;
        &:not(.no-title) {
            gap: 13px;
            h1 {
                font-size: 20px;
            }
            img {
                height: 24px;
                opacity: .35;
            }
            button {
                background-color: var(--disabledBg);
            }
        }
    }
}