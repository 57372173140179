@import './antd.alert.scss';
@import './antd.button.scss';
@import './antd.cascader.scss';
@import './antd.tabs.scss';
@import './antd.input.scss';
@import './antd.modal.scss';
@import './antd.notification.scss';
@import './antd.popconfirm.scss';
@import './antd.select.scss';
@import './antd.radio.scss';
@import './antd.result.scss';
@import './antd.drawer.scss';
@import './antd.icon.scss';
@import './antd.tooltip.scss';

#lax {
    // Disabled
    input[disabled] ,
    textarea[disabled], 
    .lx-picker-disabled,
    .lx-input-affix-wrapper-disabled,
    .lx-select-disabled  .lx-select-selector {
        background-color: var(--backgroundDisabled);
        color: var(--colorDisabled);
        &::placeholder,
        .lx-select-selection-placeholder {
            color: transparent!important;
        }
    }
}