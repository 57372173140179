#lax {
    .lax-result {
        width: 100%;
        height: 100%;
        padding: 0 15px;
        display: grid;
        place-content: center;
        transform: translateY(-25px);
        > div {
            display: grid;
            grid-row-gap: 20px;
            justify-content: center;
            h1 {
                font-weight: bold;
                font-size: 18px;
                text-align: center;
            }
            img {
                width: 300px;
                align-self: self-end;
            }
            button {
                position: relative;
                overflow: hidden;
            }
        }
    }
}