#lax {
    footer {
        height: var(--footerHeight);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 15px;
        background: rgba(246, 246, 246, .9);
        border-top: 1px solid var(--colorLine);
        position: fixed;
        padding: 0 var(--contentPadding);
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 5;
        > .left {
            margin-right: auto;
        }
        >.lx-btn:not(.lx-input-search-button),
        .lx-footer-btn {
            font-size: var(--fontSizeL);
            height: 35px;
            &:not(.lx-btn-icon-only) {
                min-width: 165px;
            }
        }
        >.lx-btn-background-ghost {
            min-width: auto!important;
            border-style: dashed;
        }
        >.lx-btn.lx-btn-icon-only {
            font-size: var(--fontSizeL);
            width: 35px;
            height: 35px;
        }
    }
}