html, body {
    width: 100%;
    scroll-behavior: smooth; 
}
body {
    background-color: var(--appBackground);
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    color: var(--colorText);
    > iframe {
        pointer-events: none;
    }
}
* {
    font-family: var(--fontFamily);
    box-sizing: border-box;
    margin: 0;
    // @removed because hides pseudo elements on ol/ul
    // padding: 0;
    position: relative;
    outline: none;
    border: none;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarThumb) var(--appBackground);
}
[type="search"]::-webkit-search-decoration,
[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
::placeholder {
    font-weight: normal;
    color: var(--colorGray);
}
